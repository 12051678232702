import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import IconBars from 'cccisd-icons/stats-bars2';
import tomQuery from 'js/graphql/dashboardFidelityTom.graphql';
import dartQuery from 'js/graphql/dashboardFidelityDart.graphql';
import wfiCareCoordQuery from 'js/graphql/dashboardFidelityWfiCareCoord.graphql';
import wfiCaregiverQuery from 'js/graphql/dashboardFidelityWfiCaregiver.graphql';
import wfiTeamMemberQuery from 'js/graphql/dashboardFidelityWfiTeamMember.graphql';
import wfiYouthQuery from 'js/graphql/dashboardFidelityWfiYouth.graphql';
import { client } from 'cccisd-apollo';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import IconTooltip from 'cccisd-icons/info2';
import classnames from 'classnames';
import moment from 'moment';
import style from './style.css';

const meanKeys = {
    1: { name: 'tom', query: tomQuery },
    2: { name: 'wfiCareCoord', query: wfiCareCoordQuery },
    3: { name: 'wfiCaregiver', query: wfiCaregiverQuery },
    4: { name: 'wfiTeamMember', query: wfiTeamMemberQuery },
    5: { name: 'wfiYouth', query: wfiYouthQuery },
    6: { name: 'dart', query: dartQuery },
};

const getWfiIds = toolTree => {
    const wfiTool = toolTree.find(tool => tool.value === 100);
    return wfiTool ? [100, ...wfiTool.children.map(child => child.value)] : [];
};

export default class MeanFidelity extends React.Component {
    static propTypes = {
        toolTree: PropTypes.array,
        timeLabel: PropTypes.string,
        monthsBack: PropTypes.number,
        sites: PropTypes.array,
    };

    state = {
        tool: null,
        assignmentId: null,
        loading: true,
        wfiIds: getWfiIds(this.props.toolTree),
        data: [],
        excludedCycles: [],
        meanMean: null,
    };

    componentDidMount = () => {
        this.setAssignment(this.props.toolTree[0].value);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.assignmentId && this.props.sites) {
            if (
                this.props.sites !== prevProps.sites ||
                this.state.assignmentId !== prevState.assignmentId
            ) {
                this.setState({ loading: true });
                this.getData();
            }
        }
    };

    getData = async () => {
        const { sites, monthsBack } = this.props;
        const { assignmentId } = this.state;
        const today = moment();
        const cutoff = today.subtract(monthsBack, 'm').format('YYYY-MM-DD');
        const response = await client.query({
            query: meanKeys[this.state.assignmentId].query,
            variables: {
                today: today.format('YYYY-MM-DD'),
                cutoff,
                sites,
                assignmentId,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });
        const rawData = response.data.flows.deploymentList.map(d => {
            const fidelity = d[meanKeys[this.state.assignmentId].name];
            return {
                cycle: `${d.label.split(':')[1]} - ${d.settings.site}`,
                mean: fidelity && fidelity.avg ? fidelity.avg.toFixed(1) : 0,
            };
        });
        let data = [];
        let excludedCycles = [];
        let totalMeans = 0;
        for (var i = 0; i < rawData.length; i++) {
            const d = rawData[i];
            if (d.mean === 0) {
                excludedCycles.push(d);
            } else {
                data.push(d);
                totalMeans += parseFloat(d.mean);
            }
        }
        const meanMean = (totalMeans / data.length).toFixed(1);
        this.setState({ data, excludedCycles, meanMean, loading: false });
    };

    setAssignment = id => {
        let assignmentId = id;
        let tool = id;
        if (this.state.wfiIds.includes(id) || id === 100) {
            if (id === 100) {
                assignmentId = this.props.toolTree.find(t => t.value === 100).children[0].value;
            }
            tool = 100;
        }
        this.setState({ tool, assignmentId });
    };

    render() {
        const tooltipText =
            'This graph shows the mean fidelity scores for each of the completed evaluation cycles of this tool type. Overall fidelity is the average of those completed evaluation cycles.';
        return (
            <div>
                <div className={style.spaceBetween}>
                    <div className={style.alignItems}>
                        <div className={style.icon} style={{ color: '#4a2e84' }}>
                            <IconBars />
                        </div>
                        <div>
                            <h2 className={style.title}>Mean Fidelity</h2>
                            <h5 className={style.subtitle}>
                                {this.state.meanMean}% overall fidelity within the last{' '}
                                {this.props.timeLabel}
                            </h5>
                        </div>
                    </div>
                    <div>
                        <ul
                            className={classnames(
                                'pagination',
                                style.noMargin,
                                style.rightAlign,
                                style.marginBottom
                            )}
                        >
                            {this.props.toolTree.map(tool => (
                                <li
                                    key={tool.value}
                                    className={tool.value === this.state.tool && 'active'}
                                >
                                    <a onClick={() => this.setAssignment(tool.value)}>
                                        {tool.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        {this.state.wfiIds.includes(this.state.assignmentId) && (
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    onChange={e => this.setAssignment(Number(e.target.value))}
                                >
                                    {this.props.toolTree
                                        .find(tool => tool.value === 100)
                                        .children.map(tool => (
                                            <option key={tool.value} value={tool.value}>
                                                {tool.label}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )}
                    </div>
                    <div className={style.tooltip}>
                        <Tooltip title={tooltipText} placement="right">
                            <IconTooltip />
                        </Tooltip>
                    </div>
                </div>

                <div style={{ width: '100%', height: '300px' }}>
                    {this.state.data.length === 0 && !this.state.loading ? (
                        <h4 style={{ textAlign: 'center', paddingTop: '5em' }}>
                            No cycles have been scored
                        </h4>
                    ) : (
                        <ResponsiveBar
                            data={this.state.data}
                            legends={[]}
                            maxValue={100}
                            keys={['mean']}
                            indexBy="cycle"
                            margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
                            axisBottom={this.state.data.length < 2}
                            colors={['#4a2e84']}
                            labelTextColor={{ from: 'color', modifiers: [['brighter', '3']] }}
                        />
                    )}
                </div>
                {this.state.excludedCycles.length > 0 && (
                    <div className={style.bottomNotice}>
                        <Modal
                            trigger={<a>{this.state.excludedCycles.length} cycles</a>}
                            title="Cycles Hidden from Mean Fidelity"
                        >
                            <ul>
                                {this.state.excludedCycles.map(e => (
                                    <li key={e.cycle}>{e.cycle}</li>
                                ))}
                            </ul>
                        </Modal>{' '}
                        hidden because no scores are available yet
                    </div>
                )}
            </div>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import IconUser from 'cccisd-icons/user';
import ProgBar from './ProgBar';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

export default class CyclePreview extends React.Component {
    static propTypes = {
        sampleSize: PropTypes.number,
        startDate: PropTypes.string,
        nowDate: PropTypes.object,
        endDate: PropTypes.string,
        respondents: PropTypes.number,
        responseRate: PropTypes.number,
        responseRateDen: PropTypes.number,
        loading: PropTypes.bool,
    };

    state = {
        respondentProg: 0,
        responseRateProg: 0,
        dateProg: 0,
        daysRemaining: 0,
    };

    componentDidMount = () => {
        if (!this.props.loading) {
            this.getRespondentProg();
            this.getDateProg();
        }
    };

    getRespondentProg = () => {
        const respondentProg = Math.floor((this.props.respondents / this.props.sampleSize) * 100);
        const responseRateProg = Math.floor(
            (this.props.responseRate / this.props.responseRateDen) * 100
        );
        const respondentsRemaining = this.props.sampleSize - this.props.respondents;
        this.setState({ respondentProg, respondentsRemaining, responseRateProg });
    };

    getDateProg = () => {
        const startDate = moment(this.props.startDate);
        const windowDays = moment(this.props.endDate).diff(startDate, 'days');
        const activeDays = this.props.nowDate.diff(startDate, 'days');
        const dateProg = Math.floor((activeDays / windowDays) * 100);
        const daysRemaining = windowDays - activeDays;
        this.setState({ dateProg, daysRemaining });
    };

    isUpcommingCycle = () => {
        // apply timezone offset since startDate is stored in UTC time
        return this.props.nowDate.diff(this.props.startDate, 'minutes') <= 0;
    };

    render() {
        if (this.props.loading) {
            return (
                <div>
                    <div className={style.flexIt}>
                        <Skeleton height={38} />
                    </div>

                    <div className={style.visuals}>
                        <Skeleton height={140} />
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className={style.flexIt}>
                    <div>
                        <div className={style.small}>Cycle Sample Size</div>
                        <div>
                            <IconUser spaceRight />
                            {this.props.sampleSize}
                        </div>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <div className={style.small}>
                            Start Date: {moment(this.props.startDate).format('MM-DD-YYYY')}
                        </div>
                        <div className={style.small}>
                            End Date: {moment(this.props.endDate).format('MM-DD-YYYY')}
                        </div>
                    </div>
                </div>
                {!this.isUpcommingCycle() ? (
                    <div className={style.visuals}>
                        {this.props.sampleSize !== 0 && (
                            <div>
                                <ProgBar
                                    prog={this.state.respondentProg}
                                    tooltip={`${this.state.respondentsRemaining} surveys remaining`}
                                    labels={[
                                        'Surveys',
                                        `Cycle Progress: ${this.state.respondentProg}%`,
                                        this.props.sampleSize,
                                    ]}
                                    placement="top"
                                />
                                <br />

                                <div>
                                    <ProgBar
                                        prog={this.state.responseRateProg}
                                        tooltip={`${this.props.responseRate} valid responses`}
                                        labels={[
                                            'Surveys',
                                            `Response Rate: ${this.state.responseRateProg}%`,
                                            this.props.responseRateDen,
                                        ]}
                                        placement="top"
                                    />
                                    <br />
                                </div>
                            </div>
                        )}
                        <ProgBar
                            prog={this.state.dateProg}
                            tooltip={`${this.state.daysRemaining} days remaining`}
                            labels={['Start', 'End']}
                            placement="bottom"
                        />
                    </div>
                ) : (
                    <div style={{ textAlign: 'center', color: '#47a6f5', marginBottom: '2em' }}>
                        This is an upcoming cycle.
                    </div>
                )}
            </div>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import IconDown from 'cccisd-icons/arrow-down5';
import IconLeft from 'cccisd-icons/arrow-left5';

export default class Collapsable extends React.Component {
    static propTypes = {
        collapsed: PropTypes.bool,
        title: PropTypes.string,
        content: PropTypes.element,
        forceUpdate: PropTypes.bool,
        undoForce: PropTypes.func,
    };

    state = {
        collapsed: this.props.collapsed,
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.collapsed !== this.props.collapsed) {
            this.setState({ collapsed: this.props.collapsed });
        }
        if (this.props.forceUpdate) {
            this.setState({ collapsed: this.props.collapsed });
            this.props.undoForce();
        }
    };

    toggle = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }));
    };

    render() {
        return (
            <div className={style.container}>
                <div className={style.header} onClick={this.toggle}>
                    <div>{this.props.title}</div>
                    <div>{this.state.collapsed ? <IconDown /> : <IconLeft />}</div>
                </div>
                {!this.state.collapsed && <div className={style.body}>{this.props.content}</div>}
            </div>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from 'cccisd-nivo/line';
import Skeleton from 'react-loading-skeleton';
import Tooltip from 'cccisd-tooltip';
import IconTooltip from 'cccisd-icons/info2';
import style from './style.css';

export default class ChartCard extends React.Component {
    static propTypes = {
        data: PropTypes.array,
        color: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        icon: PropTypes.object,
        tooltip: PropTypes.string,
    };

    render() {
        const Icon = this.props.icon;
        const { data, color } = this.props;
        return (
            <div>
                {data[0].data.length === 0 ? (
                    <div className={style.header}>
                        <div className={style.icon}>
                            <Skeleton circle height={50} width={50} />
                        </div>
                        <div>
                            <h2>
                                <Skeleton width={400} />
                            </h2>
                            <h5>
                                <Skeleton width={140} />
                            </h5>
                        </div>
                    </div>
                ) : (
                    <div className={style.spaceBetween}>
                        <div className={style.header}>
                            <div className={style.icon} style={{ color }}>
                                <Icon />
                            </div>
                            <div>
                                <h2 className={style.title}>{this.props.title}</h2>
                                <h5 className={style.subtitle}>{this.props.subtitle}</h5>
                            </div>
                        </div>
                        <div className={style.tooltip}>
                            <Tooltip title={this.props.tooltip} placement="right">
                                <IconTooltip />
                            </Tooltip>
                        </div>
                    </div>
                )}
                {data[0].data.length === 0 ? (
                    <Skeleton height={286} />
                ) : (
                    <div style={{ height: '300px' }}>
                        <ResponsiveLine
                            data={this.props.data}
                            margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
                            legends={[]}
                            yScale={{ type: 'linear', min: 0 }}
                            axisBottom={data[0].data.length < 13}
                            colors={[color]}
                        />
                    </div>
                )}
            </div>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';

export default class DateRangeSelector extends React.Component {
    static propTypes = {
        options: PropTypes.array,
        setter: PropTypes.func,
        val: PropTypes.number,
    };

    getLabel = months => {
        if (months < 12) {
            return `${months} MO`;
        }
        return `${months / 12} YR`;
    };

    render() {
        return (
            <div className="text-center">
                <nav aria-label="Session navigation">
                    <ul className="pagination">
                        {this.props.options.map(option => (
                            <li className={this.props.val === option ? 'active' : ''} key={option}>
                                <a href="#" onClick={e => this.props.setter(option)}>
                                    {this.getLabel(option)}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        );
    }
}

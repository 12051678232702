import React from 'react';
import style from './style.css';
import SiteSelector from '../../SiteSelector';

export default class ButtonBar extends React.Component {
    render() {
        return (
            <div className={style.flexIt}>
                <SiteSelector />
            </div>
        );
    }
}

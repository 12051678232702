import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import Tooltip from 'cccisd-tooltip';

export default class ProgBar extends React.Component {
    static propTypes = {
        prog: PropTypes.number,
        labels: PropTypes.array,
        placement: PropTypes.string,
        tooltip: PropTypes.string.isRequired,
    };

    renderLabels = () => {
        const { labels } = this.props;
        return (
            <div className={style.labels}>
                {labels.map(label => (
                    <div key={label}>{label}</div>
                ))}
            </div>
        );
    };

    render() {
        const { prog, placement, tooltip } = this.props;
        return (
            <div>
                {placement === 'top' && this.renderLabels()}
                {tooltip ? (
                    <Tooltip title={tooltip} size="large" placement="right">
                        <div className={style.notDone}>
                            <div className={style.done} style={{ width: `${prog}%` }} />
                        </div>
                    </Tooltip>
                ) : (
                    <div className={style.notDone}>
                        <div className={style.done} style={{ width: `${prog}%` }} />
                    </div>
                )}
                {placement === 'bottom' && this.renderLabels()}
            </div>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import style from './style.css';
import Button from 'cccisd-click-button';

export default class ToolSelector extends React.Component {
    static propTypes = {
        setter: PropTypes.func,
        val: PropTypes.array,
        toolTree: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    state = {
        expanded: [100],
    };

    handleExpand = expanded => {
        this.setState({ expanded });
    };

    handleCheck = checked => {
        this.props.setter(checked.map(str => Number(str)));
    };

    getLabel = () => {
        return 'Select Tools';
    };

    closeModal = () => {
        this.modal.current.close();
    };

    render() {
        return (
            <Modal
                ref={this.modal}
                trigger={
                    <button type="button" className={classnames('btn', 'btn-default')}>
                        <IconCheck spaceRight />

                        {this.getLabel()}
                    </button>
                }
                title={this.getLabel()}
            >
                <div className={style.flex}>
                    {this.props.toolTree.length > 0 ? (
                        <CheckboxTree
                            nodes={this.props.toolTree}
                            checked={this.props.val}
                            expanded={this.state.expanded}
                            onCheck={checked => this.handleCheck(checked)}
                            onExpand={expanded => this.handleExpand(expanded)}
                            icons={{
                                check: <IconCheck />,
                                uncheck: <IconUncheck />,
                                halfCheck: <IconHalfcheck />,
                                expandClose: <IconExpandClose />,
                                expandOpen: <IconExpandOpen />,
                                expandAll: <IconExpandOpen />,
                                collapseAll: <IconExpandClose />,
                                parentClose: <IconParentClose />,
                                parentOpen: <IconParrentOpen />,
                                leaf: <IconLeaf />,
                            }}
                        />
                    ) : (
                        <h3>Permission Denied</h3>
                    )}
                </div>
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={this.closeModal} />
                </div>
            </Modal>
        );
    }
}
